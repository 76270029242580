import "./pageServices.css";

import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { PAGE_ROUTES } from "../../utils/stateTypes";
import Services from "../sections/Services";
import Booking from "../sections/Booking";


const PageServices = () => {
  return (
    <main>
      <Helmet>
        <title>Services | TLC Nail Bar</title>
        <meta
          name={"description"}
          content={"View our range of services and choose the treatment that's right for you."}
        ></meta>
      </Helmet>

      <header className={"page-header"}>
        <img
          src={"/images/services-header.jpg"}
          className={"page-header-img"}
        />
        <div className={"standard-width"}>
          <h1>
            Services
          </h1>
          <div className={"breadcrumbs"}>
            <Link to={PAGE_ROUTES.HOME}>
              <Button
                variant={"text"}
                color={"secondary"}
              >
                Home
              </Button>
            </Link>
            <p>{"\xbb"}</p>
            <Button
              variant={"text"}
              color={"secondary"}
            >
              Services
            </Button>
          </div>
        </div>
      </header>

      <section className={"standard-width promotion-section standard-box"}>
        <img
          className={"promotion-img"}
          src={"/images/promotion-icon.png"}
        />
        <div className={"promotion-content"}>
          <h2>
            Promotion!
          </h2>
          <p>
            All treatments are 20% off on Mondays and Tuesdays!
          </p>
        </div>
      </section>

      <Services />

      <img
        className={"divider no-margin-top"}
        src={"/images/divider.png"}
      />

      <Booking />

    </main>
  );
};

export default PageServices;

