import "./pageAbout.css";

import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { PAGE_ROUTES } from "../../utils/stateTypes";
import Blocks from "../sections/Blocks";
import Booking from "../sections/Booking";


const PageAbout = () => {
  return (
    <main>
      <Helmet>
        <title>About | TLC Nail Bar</title>
        <meta
          name={"description"}
          content={"Learn more about TLC Nail Bar, a luxurious nail salon in Bedfordview."}
        ></meta>
      </Helmet>

      <header className={"page-header"}>
        <img
          src={"/images/about-header.jpg"}
          className={"page-header-img"}
        />
        <div className={"standard-width"}>
          <h1>
            About Us
          </h1>
          <div className={"breadcrumbs"}>
            <Link to={PAGE_ROUTES.HOME}>
              <Button
                variant={"text"}
                color={"secondary"}
              >
                Home
              </Button>
            </Link>
            <p>{"\xbb"}</p>
            <Button
              variant={"text"}
              color={"secondary"}
            >
              About
            </Button>
          </div>
        </div>
      </header>

      <div className={"divider-div"} />

      <Blocks
        heading1={"A love for nails"}
        content1={
          <React.Fragment>
            <p>
              TLC Nail Bar, founded by Portia Malatji, is a haven of pampering and
              self-care nestled in Bedfordview. With a passion for both aesthetics
              and wellness, Portia envisioned a space where clients could escape
              the hustle and bustle of daily life and indulge in luxurious nail treatments.
            </p>
          </React.Fragment>
        }
        image1={"/images/about-1.jpg"}
        heading2={"A focus on perfection"}
        content2={
          <React.Fragment>
            <p>
              At TLC Nail Bar, every detail is meticulously curated to create an oasis
              of relaxation. The soothing ambiance, soft music, and aromatic scents
              transport visitors to a realm of tranquility. Whether you’re seeking a
              classic manicure, a trendy nail art design, or a rejuvenating pedicure,
              the skilled technicians at TLC ensure that each service is a delightful
              experience.
            </p>
          </React.Fragment>
        }
        image2={"/images/about-2.jpg"}
      />

      <img
        className={"divider no-margin-top"}
        src={"/images/divider.png"}
      />

      <Booking />

    </main>
  );
};

export default PageAbout;

