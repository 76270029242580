
export const PAGE_ROUTES = {
  HOME: "/",
  SERVICES: "/services",
  ABOUT: "/about",
  CONTACT: "/contact",
};

export const LOADING_STATE = {
  NOT_LOADING: 0,
  LOADING: 1,
  LOADED: 2,
  ERROR: 3,
};