import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#A53860",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: "Roboto",
    allVariants: {
      "@media (max-width:1200px)": {
        fontSize: "0.9em",
      },
      "@media (max-width:800px)": {
        fontSize: "0.8em",
      },
    },
  },
  overrides: {
   
  },
});

export default theme;