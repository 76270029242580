import "./pageContact.css";

import React from "react";
import { Button, TextField, Select, FormControl, InputLabel, MenuItem, CircularProgress } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { PAGE_ROUTES, LOADING_STATE } from "../../utils/stateTypes";

const styles = {
  inputHalf: {
    width: "calc(50% - 10px)",
    marginBottom: 20,
    marginRight: 20,
  },
  inputHalf2: {
    width: "calc(50% - 10px)",
    marginBottom: 20,
  },
  input: {
    marginBottom: 20,
    textAlign: "left",
  },
  submit: {
    padding: 10,
  },
};

const sendMessage = async (name, email, type, date, message, setSendState) => {
  setSendState(LOADING_STATE.LOADING);
  const response = await fetch("/api/Contact/SendContactMessage", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      date,
      type,
      message,
    }),
  });
  if (response.ok) {
    setSendState(LOADING_STATE.LOADED);
  }
  else {
    setSendState(LOADING_STATE.ERROR);
  }
};


const PageContact = () => {

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [type, setType] = React.useState("Making an appointment");
  const [date, setDate] = React.useState(null);
  const [message, setMessage] = React.useState("");
  const [sendState, setSendState] = React.useState(LOADING_STATE.NOT_LOADING);

  let submitLabel = "Send";
  if (sendState === LOADING_STATE.LOADING) {
    submitLabel = <CircularProgress size={25} />;
  }
  else if (sendState === LOADING_STATE.LOADED) {
    submitLabel = "Sent!";
  }
  else if (sendState === LOADING_STATE.ERROR) {
    submitLabel = "Retry";
  }

  return (
    <main>
      <Helmet>
        <title>Contact | TLC Nail Bar</title>
        <meta
          name={"description"}
          content={"Get in touch, or book an appointment. We can't wait to see you!"}
        ></meta>
      </Helmet>
      <header className={"page-header"}>
        <img
          src={"/images/contact-header.jpg"}
          className={"page-header-img"}
        />
        <div className={"standard-width"}>
          <h1>
            Contact
          </h1>
          <div className={"breadcrumbs"}>
            <Link to={PAGE_ROUTES.HOME}>
              <Button
                variant={"text"}
                color={"secondary"}
              >
                Home
              </Button>
            </Link>
            <p>{"\xbb"}</p>
            <Button
              variant={"text"}
              color={"secondary"}
            >
              Contact
            </Button>
          </div>
        </div>
      </header>
      
      <section className={"standard-width standard-box contact-form-section"}>
        <h2>Get in touch</h2>
        <form
          className={"contact-form"}
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage(name, email, type, date, message, setSendState);
          }}
        >
          <TextField
            label={"Name"}
            variant={"filled"}
            style={styles.inputHalf}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label={"Email"}
            variant={"filled"}
            style={styles.inputHalf2}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <FormControl
            variant={"filled"}
            style={styles.input}
            fullWidth
            required
          >
            <InputLabel>I am enquiring about</InputLabel>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem value={"Making an appointment"}>Making an appointment</MenuItem>
              <MenuItem value={"General enquiry"}>General enquiry</MenuItem>
            </Select>
          </FormControl>
          {
            (type === "Making an appointment") && (
              <DateTimePicker
                label={"Date & Time"}
                inputVariant={"filled"}
                variant={"dialog"}
                DialogProps={{
                  disableScrollLock: true,
                }}
                style={styles.input}
                value={date}
                onChange={(e) => setDate(e)}
                minDate={new Date()}
                fullWidth
                required
              />
            )
          }
          
          {
            (type === "General enquiry") && (
              <TextField
                label={"How can we help?"}
                variant={"filled"}
                style={styles.input}
                fullWidth
                multiline
                minRows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            )
          }
          <Button
            variant={"contained"}
            color={"primary"}
            type={"submit"}
            fullWidth
            style={styles.submit}
            disabled={(sendState === LOADING_STATE.LOADING || sendState === LOADING_STATE.LOADED)}
          >
            {submitLabel}
          </Button>
        </form>
      </section>

      <section className={"standard-width contact-details-section"}>
        <div className={"contact-details"}>
          <h2>Hours</h2>
          <table>
            <tbody>
              <tr>
                <td>Monday</td>
                <td>9:00am - 5:00pm</td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>9:00am - 5:00pm</td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>9:00am - 5:00pm</td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>9:00am - 5:00pm</td>
              </tr>
              <tr>
                <td>Friday</td>
                <td>9:00am - 8:00pm</td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>9:00am - 8:00pm</td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>Closed</td>
              </tr>
            </tbody>
          </table>

          <h2>Contact</h2>
          <table>
            <tbody>
              <tr>
                <td>081 467 0330</td>
              </tr>
              <tr>
                <td>tlcnails0710@gmail.com</td>
              </tr>
              <tr>
                <td>50 van Buuren Road Bedfordview Johannesburg, South Africa</td>
              </tr>
            </tbody>
          </table>
        </div>

        <iframe
          className={"map"}
          src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d895.1104204414846!2d28.136716800000002!3d-26.182306699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9511d221ca4f91%3A0x8bf52b5ce3b41d2f!2sTLC%20Nail%20Bar!5e0!3m2!1sen!2snl!4v1712152154630!5m2!1sen!2snl"}
          allowFullScreen={true}
          loading={"lazy"}
          referrerPolicy={"no-referrer-when-downgrade"}
        />
      </section>
    </main>
  );
};

export default PageContact;

