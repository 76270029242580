/* eslint-disable react-hooks/exhaustive-deps */
import "./css/normalize.css"; //CSS reset
import "./css/site.css";

import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { PAGE_ROUTES } from "../utils/stateTypes";
import Page404 from "./home/404";
import ScrollToTop from "./general/ScrollToTop";
import TopNav from "./home/TopNav";
import Footer from "./home/Footer";
import Home from "./home/Home";
import PageServices from "./services/PageServices";
import PageAbout from "./about/PageAbout";
import PageContact from "./contact/PageContact";

const App = () => {
  let location = useLocation();

  return (
    <main>
      <ScrollToTop />
      <TopNav />
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames={"fade"}
          timeout={300}
        >
          <Switch location={location}>
            <Route
              exact
              path={PAGE_ROUTES.HOME}
              component={Home}
            />
            <Route
              exact
              path={PAGE_ROUTES.SERVICES}
              component={PageServices}
            />
            <Route
              exact
              path={PAGE_ROUTES.ABOUT}
              component={PageAbout}
            />
            <Route
              exact
              path={PAGE_ROUTES.CONTACT}
              component={PageContact}
            />
            <Route
              component={Page404}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </main>
  );
};

export default App;