import "./topNav.css";

import React from "react";
import { Link } from "react-router-dom";
import { Button, IconButton, Drawer } from "@material-ui/core";
import { Menu, Close } from "@material-ui/icons";

import { PAGE_ROUTES } from "../../utils/stateTypes";

// Material UI styles
const styles = {
  menuButton: {
    marginRight: "20px",
  },
  menuFeaturedButton: {
    width: "200px",
  },

  drawerClose: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  drawerButton: {
    width: "100%",
    padding: "15px",
  },
};

//Set up a function to update isScrolled when scrolling
const handleScroll = (setIsScrolled) => (
  () => {
    const scrolled = window.scrollY > 50;
    setIsScrolled(scrolled);
  }
);


const TopNav = () => {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const closeDrawer = React.useCallback(() => {
    setShowDrawer(false);
  }, []);
  const openDrawer = React.useCallback(() => setShowDrawer(true), []);

  const [isScrolled, setIsScrolled] = React.useState(false);
  React.useEffect(() => {
    let checkIsScrolledFn = setInterval(handleScroll(setIsScrolled), 100);
    return () => {
      clearInterval(checkIsScrolledFn);
    };
  }, []);

  return (
    <nav className={`menu-top ${isScrolled ? "scrolled" : ""}`}>
      <div className={"menu-top-inner standard-width"}>
        <div className={"menu-top-links"}>
          <Link
            to={PAGE_ROUTES.HOME}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              style={styles.menuButton}
            >
              Home
            </Button>
          </Link>
          {/* <Link
            to={PAGE_ROUTES.SERVICES}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              style={styles.menuButton}
            >
              Services
            </Button>
          </Link> */}
          <Link
            to={PAGE_ROUTES.ABOUT}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              style={styles.menuButton}
            >
              About
            </Button>
          </Link>
        </div>

        <Link
          to={PAGE_ROUTES.HOME}
          className={"menu-top-logo"}
        >
          <img
            src={"/images/Logo-Black.png"}
            className={"menu-top-logo"}
          />
        </Link>

        <div className={"menu-top-links"}>
          <Link
            to={PAGE_ROUTES.CONTACT}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              style={styles.menuButton}
            >
              Contact
            </Button>
          </Link>
          <Button
            variant={"contained"}
            color={"primary"}
            style={styles.menuFeaturedButton}
            href={"https://www.fresha.com/a/tlc-nail-bar-germiston-50-van-buuren-road-edmpmnf0/booking?menu=true&pId=1371825&dppub=true"}
          >
            Make an appointment
          </Button>
        </div>

        {/* =================== MOBILE MENU =================== */}
        <span className={"mobile-menu-toggle"}>
          <IconButton onClick={openDrawer}>
            <Menu />
          </IconButton>
        </span>
      </div>

      <Drawer
        anchor={"right"}
        open={showDrawer}
        onClose={closeDrawer}
      >
        <div className={"drawer-inner"}>
          <IconButton
            onClick={closeDrawer} 
            style={styles.drawerClose}
          >
            <Close />
          </IconButton>
          
          <div>
            <Link to={PAGE_ROUTES.HOME}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                Home
              </Button>
            </Link>
            {/* <Link to={PAGE_ROUTES.SERVICES}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                Services
              </Button>
            </Link> */}
            <Link to={PAGE_ROUTES.ABOUT}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                About
              </Button>
            </Link>
            <Link to={PAGE_ROUTES.CONTACT}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                Contact
              </Button>
            </Link>
            <Button
              variant={"text"}
              color={"secondary"}
              size={"large"}
              style={styles.drawerButton}
              onClick={closeDrawer}
              href={"https://www.fresha.com/a/tlc-nail-bar-germiston-50-van-buuren-road-edmpmnf0/booking?menu=true&pId=1371825&dppub=true"}
            >
              Make an appointment
            </Button>
          </div>

          <img
            className={"drawer-menu-logo"}
            src={"/images/Logo-White.png"}
          />
        </div>
      </Drawer>
    </nav>
  );
};

export default TopNav;