import "./galleryBlock.css";

import React from "react";


const GalleryBlock = ({ image1, image2=null, image3=null, isReversed=false, isRight=false }) => {

  return (
    <div className={["gallery-block", isReversed ? "reversed" : "", isRight ? "right" : ""].join(" ")}>
      {
        (!isReversed) && (
          <img
            src={image1}
            className={"gallery-img-lg"}
          />
        )
      }
      

      <div>
        {
          (image2) && (
            <img
              src={image2}
              className={"gallery-img-sm"}
            />
          )
        }

        {
          (image3) && (
            <img
              src={image3}
              className={"gallery-img-sm"}
            />
          )
        }

        {
          (isReversed) && (
            <img
              src={image1}
              className={"gallery-img-lg"}
            />
          )
        }
      </div>
    </div>
  );
};

export default GalleryBlock;