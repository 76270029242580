import "./services.css";

import React from "react";

const TREATMENTS = [
  {
    name: "Hand Treatments",
    treatments: [
      {
        name: "Sculpture",
        price: "R480",
      },
      {
        name: "Sculpture + Gel",
        price: "R500",
      },
      {
        name: "Acrylic Overlay",
        price: "R380",
      },
      {
        name: "Acrylic Overlay + Gel",
        price: "R450",
      },
      {
        name: "Acrylic + Tips",
        price: "R450",
      },
      {
        name: "Gel Overlay",
        price: "R280",
      },
      {
        name: "Natrual French Gel",
        price: "R400",
      },
      {
        name: "Manicure (No Product)",
        price: "R200",
      },
      {
        name: "Manicure + Gel",
        price: "R380",
      },
      {
        name: "Manicure + Nail Polish",
        price: "R250",
      },
      {
        name: "Soft Gel Tips",
        price: "R390",
      },
      {
        name: "2 Weeks Fill",
        price: "R300",
      },
      {
        name: "3 Weeks Fill",
        price: "R320",
      },
      {
        name: "4 Weeks Fill",
        price: "R350",
      },
    ],
  },
  {
    name: "Foot Treatments",
    treatments: [
      {
        name: "Gel Toes",
        price: "R280",
      },
      {
        name: "Acrylic Toes",
        price: "R350",
      },
      {
        name: "Acrylic Toes + Gel",
        price: "R500",
      },
      {
        name: "Pedicure",
        price: "R250",
      },
      {
        name: "Pedicure + Nail Polish",
        price: "R280",
      },
      {
        name: "Pedicure + Gel Toes",
        price: "R400",
      },
      {
        name: "Pedicure + Acrylic Toes",
        price: "R500",
      },
      {
        name: "Soak Off Toes",
        price: "R90",
      },
      {
        name: "Pedi + Paraffin Wax",
        price: "R150",
      },
      {
        name: "Pedi + Colours Eater",
        price: "R150",
      },
    ],
  },
  {
    name: "Art",
    treatments: [
      {
        name: "Basic Nail Art (Per Nail)",
        price: "R25",
      },
      {
        name: "3D Nail Art (Per Nail)",
        price: "R50",
      },
      {
        name: "Stickers (Per Nail)",
        price: "R20",
      },
      {
        name: "Encapsulated Art (Per Nail)",
        price: "R80",
      },
      {
        name: "Diamanté (Per Nail)",
        price: "R20",
      },
    ],
  },
  {
    name: "Express Treatments",
    treatments: [
      {
        name: "Express Mani",
        price: "R180",
      },
      {
        name: "Express Mani + Nail Varnish",
        price: "R280",
      },
      {
        name: "Express Mani + Gel",
        price: "R350",
      },
      {
        name: "Express Mani + Acrylic",
        price: "R360",
      },
      {
        name: "Express Pedi",
        price: "R180",
      },
      {
        name: "Express Pedi + Nail Varnish",
        price: "R200",
      },
      {
        name: "Express Pedi + Gel Toes",
        price: "R330",
      },
      {
        name: "Express Pedi + Acrylic Toes",
        price: "R400",
      },
    ],
  },
  {
    name: "Other",
    treatments: [
      {
        name: "Soak Off",
        price: "R100",
      },
      {
        name: "Soak Off (With New Set)",
        price: "R50",
      },
      {
        name: "Acrylic Nail Repair",
        price: "R40",
      },
      {
        name: "Gel Nail Repair",
        price: "R30",
      },
    ],
  },
  {
    name: "Combos",
    treatments: [
      {
        name: "Gel Overlay Hands & Feet",
        price: "R500",
      },
      {
        name: "Gel Overlay Hands & Feet (2 Soaks)",
        price: "R560",
      },
      {
        name: "Pedi Gel + Gel Hands",
        price: "R600",
      },
      {
        name: "Mani + Gel + Pedi Gel (Free Soak)",
        price: "R780",
      },
      {
        name: "Mani Gel + Pedi Gel (No Soak)",
        price: "R700",
      },
    ],
  },
];


const Home = () => {
  return (
    <section className={"services-section"}>
      <img
        className={"services-bg"}
        src={"/images/paint-bg.jpg"}
      />
      <div className={"standard-width"}>
        <div className={"services-block"}>
          {
            TREATMENTS.map((treatmentCat, i) => (
              <div
                key={i}
                className={["service-cat", (i % 2 === 1) ? "shown-when-sm" : ""].join(" ")}
              >
                <h3>{treatmentCat.name}</h3>
                {
                  treatmentCat.treatments.map((treatment, j) => (
                    <div
                      className={"service-row"}
                      key={j}
                    >
                      <div className={"service-row-main"}>
                        <p className={"service-name"}>{treatment.name}</p>
                        <div className={"dots"} />
                        <p className={"service-price"}>{treatment.price}</p>
                      </div>
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>
        <div className={"services-block hidden-when-sm"}>
          {
            TREATMENTS.map((treatmentCat, i) => {
              if (i % 2 === 0) {
                return null;
              }
              return (
                <div
                  key={i}
                  className={"service-cat"}
                >
                  <h3>{treatmentCat.name}</h3>
                  {
                    treatmentCat.treatments.map((treatment, j) => (
                      <div
                        className={"service-row"}
                        key={j}
                      >
                        <div className={"service-row-main"}>
                          <p className={"service-name"}>{treatment.name}</p>
                          <div className={"dots"} />
                          <p className={"service-price"}>{treatment.price}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    </section>
  );
};

export default Home;

