import "./blocks.css";

import React from "react";


const Blocks = ({ heading1, content1, image1, heading2, content2, image2 }) => {
  return (
    <section className={"standard-width blocks-section"}>
      <div className={"blocks-outer"}>
        <div className={"block-content"}>
          <h2>{heading1}</h2>
          {content1}
        </div>
        <img
          className={"block-img"}
          src={image1}
        />
      </div>

      <div className={"blocks-outer reversed"}>
        <div className={"block-content"}>
          <h2>{heading2}</h2>
          {content2}
        </div>
        <img
          className={"block-img"}
          src={image2}
        />
      </div>
    </section>
  );
};

export default Blocks;

